<template>
  <div id="administration">
    <app-menu class="paddingBottom"></app-menu>
    <div class="container text-center">
      <h1 class="pt-lg-4 pb-lg-4">Vítá Vás administrace k webu:</h1>
      <a class="pb-lg-4" href="/">maratonhudby.cz</a>
      <h3 class="pb-lg-4">Přejeme příjemnou zábavu!</h3>
    </div>
  </div>
</template>

<script>
import AdminMenu from "@/components/admin/AdminMenu";
import axios from "@/api/axiom-instance";

export default {
  name: "administration",
  components: {
    'app-menu': AdminMenu
  },
  data() {
    return {
      contact: {name: "", role: {cs: "", en: ""}, phoneNumber: "", email: ""},
      period: {name: {cs: "cs", en: "en"}, state: "ACTUAL", link: "https://..", note: {cs: "cs", en: "en"}},
      stage: {name: {cs: "cs", en: "en"}, description: {cs: "cs", en: "en"}, note: {cs: "cs", en: "en"}},
      event: {name: {cs: "cs", en: "en"}, stageId: 1, periodId: 1, link: "https://blabla", note: {cs: "cs", en: "en"}}
    }
  },
  methods: {
    create: function () {
      axios.post("/event", this.event).then((success) => {
        console.log(success)
      }).catch((failed) => {
        console.log(failed)
      })
    }
  }

}
</script>

<style scoped>
#administration {
  background-color: white;
  height: 100vh;
  color: black;
  font-family: "Transcript Mono";
}
a {
  padding-bottom: 2em;
  display: block;
  color: black;
  text-decoration: underline;
  font-size: 2em;
}
a:hover {
  text-decoration: none;
  color: #152326;
}
</style>
